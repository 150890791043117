import { useRequestPasswordReset } from '@modules/auth/hooks';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { EmailSentConfirmation } from './components';

export default function ForgotPassword() {
	const [email, setEmail] = useState('');

	const { mutate: forgotPassword, isSuccess } = useRequestPasswordReset();

	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		forgotPassword(email);
	}

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		setEmail(event.target.value);
	}

	console.log(isSuccess);

	if (isSuccess) return <EmailSentConfirmation email={email} />;

	return (
		<div className="flex min-h-full flex-1 flex-col justify-center py-12">
			<div className="mx-auto basis-1">
				<div className="mt-10">
					<div className="bg-neutral-90 px-6 py-2 mx-2 rounded-md">
						<div className="flex justify-between h-auto">
							<img
								className='h-24 w-1/3'
								src="/C2.svg"
								alt="Creovia"
							/>
						</div>
						<div className='text-neutral-5'>
							<h1 className='font-medium mb-2 text-2xl'>Reset Password</h1>
							<div className='text-sm flex'>
								<Link to="/"
									className='ml-2 text-primary-40 cursor-pointer'>
											Go back
								</Link>
							</div>
						</div>
						<div className="mt-8 border-t-2 pt-2 text-neutral-5">
							<div className="mt-4">
								<form className='flex flex-col' onSubmit={handleSubmit}>
									<input type='email' value={email} onChange={handleChange} className='mb-6 p-2 text-sm w-full bg-neutral-80 placeholder-neutral-40 rounded' placeholder='Email' />

									<button type='submit' className='px-4 py-2 bg-primary-40 my-4 w-full flex justify-center text-neutral-90 rounded'>Create an Account</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}