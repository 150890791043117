import { Column } from '@tanstack/react-table';
import { CSSProperties } from 'react';

export default function getCommonPinningStyles<T>(column: Column<T>, isCell?: boolean): { className: string, style: CSSProperties } {
	const isPinned = column.getIsPinned();
	const isLastLeftPinnedColumn = isPinned && column.getIsLastColumn('left');
	const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

	return {
		className: ` ${isFirstRightPinnedColumn && isCell ? 'border-l-2 border-neutral-80 bg-neutral-90 ' : ''} ${isLastLeftPinnedColumn && isCell ? 'border-r-2 border-neutral-80 bg-neutral-90' : ''}`,
		style: {
			// boxShadow: isLastLeftPinnedColumn ? 'rgba(0,0,0,0.5) -4px 0px 4px -4px inset':  undefined,
			display: column.getIsVisible() ? 'inherit' : 'none',
			left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
			right: isPinned === 'right' ? '0' : undefined,
			opacity: 0.95,
			position: isPinned ? 'sticky' : 'relative',
			width: column.getSize(),
			zIndex: isPinned ? 1 : 0,
		}
	};
}