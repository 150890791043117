import { Table } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { DebouncedInput } from '../../utils';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { FilterPopover } from './components';
import ActiveFilters from '../../../Filters/components/ActiveFilters';

interface FilterProps<T> {
	table: Table<T>
}

const Filters = <T,>({ table }: FilterProps<T>) => {
	const [selectedColumnId, setSelectedColumnId] = useState<string | number | null>(null);

	function handleColumnClick(columnId: string | number) {
		if (selectedColumnId === columnId) {
			setSelectedColumnId(null);
		} else {
			setSelectedColumnId(columnId);
		}
	}

	return (
		<div className={'flex items-center mb-3 w-full'}>
			<div className={'px-2 py-2 bg-neutral-95 border border-neutral-70 rounded relative inline-flex items-center gap-2 max-w-full flex-shrink-0 mb-2'}>
				<MagnifyingGlassIcon className={'h-5 aspect-square text-neutral-60 absolute top-1/2 left-0 translate-x-1/2 -translate-y-1/2'} />
				<DebouncedInput className={'bg-neutral-95 pl-6 rounded focus:outline-0 w-full'} placeholder={'Search...'} value={''} onChange={table.setGlobalFilter} />
				<div className={'h-6 w-1 bg-neutral-80 rounded-full'} />
				<FilterPopover table={table} />
			</div>
			{table.getAllColumns().some((column) => column.getIsFiltered())
				? <ActiveFilters columns={table.getAllColumns()} />
				: null
			}
		</div>
	);
};

export default Filters;