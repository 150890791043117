import {
	ColumnDef, ColumnFilter, ColumnFiltersState, FilterFn, FiltersTableState,
	getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel, getSortedRowModel, OnChangeFn, Row, SortingState,
	useReactTable
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';

export default function useTable<T>(data: T[], mutatedColumns: ColumnDef<T>[]) {

	const [sorting, setSorting] = useState<SortingState>([]);
	const [globalFilter, setGlobalFilter] = useState();
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	const onColumnFilterChange: OnChangeFn<ColumnFiltersState> = (updaterOrValue) => {
		let updatedColumnFilters = updaterOrValue;
		if (typeof updaterOrValue === 'function') {
			// console.log('onColumnFilterChange', updaterOrValue(columnFilters), typeof updaterOrValue);
			updatedColumnFilters = (updaterOrValue(columnFilters) as ColumnFilter[]).filter((filter) => (filter.value as string[]).length > 0);
		}
		setColumnFilters(updatedColumnFilters);
	};


	const customGlobalFilterFn: FilterFn<T> = (row, columnId, filterValue) => {
		const column = table.getColumn(columnId);
		const cellValue = row.getValue(columnId);
		const rowData = row.original;

		if (!column || !cellValue) return false;
		else if (column.columnDef.meta?.search_reference_keys) {
			const { search_reference_keys } = column.columnDef.meta;
			const concatenatedString = search_reference_keys.map(key => rowData[key as keyof T]).join(' ');
			return concatenatedString.toLowerCase().includes(filterValue.toLowerCase());
		} else {
			return cellValue.toString().toLowerCase().includes(filterValue.toLowerCase());
		}
	};

	const table = useReactTable({
		columns: mutatedColumns,
		data,
		state: {
			sorting,
			globalFilter,
			columnFilters
		},
		enableColumnPinning: true,
		enableGlobalFilter: true,
		globalFilterFn: customGlobalFilterFn,
		enableHiding: true,
		onGlobalFilterChange: setGlobalFilter,
		onSortingChange: setSorting,
		onColumnFiltersChange: onColumnFilterChange,
		getCoreRowModel: getCoreRowModel<T>(),
		getFilteredRowModel: getFilteredRowModel<T>(),
		getFacetedRowModel: getFacetedRowModel<T>(),
		getSortedRowModel: getSortedRowModel<T>(),
		getFacetedUniqueValues: getFacetedUniqueValues<T>(),
		getFacetedMinMaxValues: getFacetedMinMaxValues<T>(),
		columnResizeMode: 'onChange',
		initialState: {
			columnPinning: {
				left: [],
				right: ['interactions']
			},
		}
		// getPaginationRowModel: getPaginationRowModel<T>(),
	});


	return {
		table
	};
}



