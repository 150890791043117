import React from 'react';
import { useRef } from 'react';
import { useCalendarCell } from '@react-aria/calendar';
import { useLocale } from '@react-aria/i18n';
import { isSameDay, getDayOfWeek, isSameMonth } from '@internationalized/date';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';

interface CalendarCellProps {
  state: any;
  date: any;
  currentMonth: any;
  isPressed?: ()=>void;
  pickDate: (string: string) => void,
}

export default function CalendarCell({ state, date, currentMonth, isPressed, pickDate }: CalendarCellProps) {
	const ref = useRef<HTMLDivElement | null>(null);
	const {
		cellProps,
		buttonProps,
		isSelected,
		isDisabled,
		formattedDate
	} = useCalendarCell({ date }, state, ref);

	const isOutsideMonth = !isSameMonth(currentMonth, date);

	// The start and end date of the selected range will have
	// an emphasized appearance.
	const isSelectionStart = state.highlightedRange
		? isSameDay(date, state.highlightedRange.start)
		: isSelected;
	const isSelectionEnd = state.highlightedRange
		? isSameDay(date, state.highlightedRange.end)
		: isSelected;

	// We add rounded corners on the left for the first day of the month,
	// the first day of each week, and the start date of the selection.
	// We add rounded corners on the right for the last day of the month,
	// the last day of each week, and the end date of the selection.
	const { locale } = useLocale();
	const dayOfWeek = getDayOfWeek(date, locale);
	const isRoundedLeft =
    isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
	const isRoundedRight =
    isSelected &&
    (isSelectionEnd ||
      dayOfWeek === 6 ||
      date.day === date.calendar.getDaysInMonth(date));

	const { focusProps, isFocusVisible } = useFocusRing();

	return (
		<td
			{...cellProps}
			className={`py-0.5 relative ${isFocusVisible ? 'z-10' : 'z-0'}`}
		>
			<div
				{...mergeProps(buttonProps, focusProps)}
				ref={ref}
				hidden={isOutsideMonth}
				className={`w-10 h-10 outline-none group ${
					isRoundedLeft ? 'rounded-l-full' : ''
				} ${isRoundedRight ? 'rounded-r-full' : ''} ${
					isSelected ? 'bg-primary-40' : ''
				} ${isDisabled ? 'disabled' : ''}`}
			>
				<div
					className={`w-full h-full rounded-full flex items-center justify-center ${
						isDisabled ? 'text-gray-400' : ''
					} ${
						// Focus ring, visible while the cell has keyboard focus.
						isFocusVisible
							? 'ring-2 group-focus:z-2 ring-primary-5 ring-offset-2'
							: ''
					} ${
						// Darker selection background for the start and end.
						isSelectionStart || isSelectionEnd
							? 'bg-primary-40 hover:bg-primary-30 text-neutral-95 '
							: ''
					} ${
						// Hover state for cells in the middle of the range.
						isSelected && !(isSelectionStart || isSelectionEnd)
							? 'bg-primary-40 hover:bg-primary-30 text-neutral-95'
							: ''
					} ${
						// Hover state for non-selected cells.
						!isSelected && !isDisabled ? 'hover:bg-primary-20 hover:text-neutral-95 text-neutral-5' : ''
					} cursor-default`}
					onClick={isPressed}
				>
					{formattedDate}
				</div>
			</div>
		</td>
	);
}
