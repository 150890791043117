import { GroupGridItem as GroupGridItemType, AppGroup } from '@types';
import { useNavigate } from 'react-router-dom';

interface Props {
	group: AppGroup;
	onGroupClick: (group: AppGroup) => void;
}
export default function GroupGridItem({ group, onGroupClick }: Props) {
	const navigate = useNavigate();

	return (
		<div className="flex gap-2">
			<div key={group.id}
				style={{
					backgroundImage: `url(${group.group.banner_img ? group.group.banner_img.length > 0 ? group.group.banner_img : '/images/404.png' : '/images/404.png'})`
				}}
				className="relative bg-center bg-cover flex flex-wrap flex-col basis-1/4 aspect-video justify-end  border overflow-hidden cursor-pointer" onClick={() => onGroupClick(group)}>
				<div className='backdrop-blur-lg p-2'>
					<h2 className="text-white py-2">{group.group.name}</h2>
				</div>
			</div>
		</div>
	);
}