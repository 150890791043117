import React from 'react';

export default function AppLayout({ children, noPadding }: { children: React.ReactNode, noPadding?: boolean }) {
	const h = window.innerHeight;
	return (
		<>
			<div
				className=" pb-5  w-full mx-auto"

				style={{ minHeight: `${h - 130}px` }}
			>
				{children}
			</div>
		</>
	);
}