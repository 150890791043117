import React from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { DebouncedInput } from '../../../../../../utils';
import { Column } from '@tanstack/react-table';
import { StringFilters, RangeFilters } from './components';
import DateFilters from './components/DateFilters';

interface UniqueValuesListProps<T> {
	uniqueValues: Map<string, number>;
	column: Column<T>
}
export default function UniqueValuesList<T>({ uniqueValues, column }: UniqueValuesListProps<T>) {

	function isFilterApplied(value: string) {
		const appliedFilters = column?.getFilterValue() ? [...column?.getFilterValue() as Array<string>] : [];
		if (appliedFilters.length < 1) return false;
		return appliedFilters.includes(value);
	}

	function handleFilterClick(value: string) {
		const appliedFilters = column.getFilterValue() ? [...column.getFilterValue() as Array<string>] : [];
		if (isFilterApplied(value)) {
			column.setFilterValue(appliedFilters.filter(filter => filter !== value));
			return;
		}
		column.setFilterValue([...appliedFilters, value]);
	}

	return (
		<div className={'h-[380px] border-neutral-70 border-l rounded rounded-l-none mt-3 bg-neutral-70 w-64 relative'}>
			{column.columnDef.meta?.data_type === 'str'
				? <StringFilters handleFilterClick={handleFilterClick} isFilterApplied={isFilterApplied} uniqueValues={uniqueValues} column={column} />
				: column.columnDef.meta?.data_type === 'int' || column.columnDef.meta?.data_type === 'float' || column.columnDef.meta?.data_type === 'num' || column.columnDef.meta?.data_type === 'range'
					? <RangeFilters column={column} />
					: column.columnDef.meta?.data_type === 'date'
						? <DateFilters column={column} />
						: null
			}
		</div>
	);
}
