import { Column, Table } from '@tanstack/react-table';
import { Popover, Transition } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { UniqueValuesList } from './components';
import { motion } from 'framer-motion';

interface FilterPopoverProps<T> {
	table: Table<T>;
}
export default function FilterPopover<T>({ table }: FilterPopoverProps<T>) {

	const columns = table.getAllColumns()
	const [selectedFilterColumn, setSelectedFilterColumn] = React.useState<Column<T> | null>(null);

	function handleColumnClick(column: Column<T>) {
		if (selectedFilterColumn?.id === column.id) {
			setSelectedFilterColumn(null);
		} else {
			setSelectedFilterColumn(column);
		}
	}

	const columnFilterVariants = {
		'inactive': { borderRadius: '0 0 0 0', marginLeft: 0 },
		'active': { borderRadius: '20px 0 0 20px', marginLeft: 7 },
	};

	return (
		<Popover className={'relative'}>
			<Popover.Button className={'py-2 px-2 bg-neutral-80 hover:bg-neutral-70 rounded inline-flex items-center gap-1 text-neutral-50 hover:text-primary-40 transition duration-200'}>
				<FunnelIcon className={'h-5 aspect-square'} />
			</Popover.Button>
			<Transition
				as={React.Fragment}
				enter="transition ease-out duration-200"
				enterFrom="opacity-0 translate-y-1"
				enterTo="opacity-100 translate-y-0"
				leave="transition ease-in duration-150"
				leaveFrom="opacity-100 translate-y-0"
				leaveTo="opacity-0 translate-y-1"
			>
				<Popover.Panel className={'absolute z-30 flex text-sm '}>
					<div className={`w-44 mt-3 bg-neutral-80 shadow-lg rounded h-[380px] overflow-auto relative ${selectedFilterColumn ? 'rounded-r-none' : ''}`}>
						<h3 className={'text-lg font-semibold p-2 sticky'}>Filters</h3>
						<div className={''}>
							{columns.map(column => (
								column.getCanFilter()
									? <motion.div
										key={column.id}
										variants={columnFilterVariants}
										animate={column.id === selectedFilterColumn?.id ? 'active' : 'inactive'}
										transition={{ duration: 0.2, type: 'tween' }}
										onClick={() => {
											handleColumnClick(column);
										}}
										className={`py-1 px-2 my-1 flex cursor-pointer justify-between items-center  transition duration-200 ${column.id === selectedFilterColumn?.id ? 'bg-neutral-70' : 'hover:bg-neutral-70/50  '}`}>
										<h3 className={'truncate'}>{column.columnDef.header as string}</h3>
										<span>{<ChevronRightIcon className={'h-5 aspect-square'} />}</span>
									</motion.div>
									: null
							))}
						</div>
					</div>
					{selectedFilterColumn ? <UniqueValuesList uniqueValues={selectedFilterColumn.getFacetedUniqueValues()} column={selectedFilterColumn} /> : null}
				</Popover.Panel>
			</Transition>
		</Popover>
	);
}