import { useMutation, useQuery, useQueryClient } from '@hooks';
import { setAuthorizationHeader } from 'services/axiosInstance';
import {
	login,
	updatePassword,
	resetPassword,
	requestPasswordReset,
	fetchResetPasswordToken,
	requestRegistrationEmail,
	createAccount,
} from '../api';
import { LoginCredentialsPayload, PasswordChangePayload } from '../schemas';

export function useLogin() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (values: LoginCredentialsPayload) => login(values),
		onSuccess: async (data) => {
			localStorage.setItem('refresh-token', data.refresh);
			setAuthorizationHeader(data.access);
			await queryClient.invalidateQueries({queryKey: ['user']});
		}
	});
}

export function useUpdatePassword() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: updatePassword,
		onSuccess: async (user) => {
			queryClient.setQueryData(['user'], user);
		},
	});
}

export function useRequestPasswordReset() {
	return useMutation({
		mutationFn: (email: string) => requestPasswordReset(email),

	});
}

export function useResetPassword() {
	return useMutation({
		mutationFn: resetPassword,
	});
}

export function useResetPasswordToken(code: string) {
	return useQuery({
		queryKey: ['resetPasswordToken'],
		queryFn: () => fetchResetPasswordToken(code),
	});
}

export function useSendRegistrationEmail() {
	return useMutation({
		mutationFn: (email: string) => requestRegistrationEmail(email),
	});
}

export function useCreateAccount(code: string) {
	return useMutation({
		mutationFn: (payload: PasswordChangePayload & { firstName: string, lastName: string, phoneNumber: string }) => createAccount(code, payload),
	});
}