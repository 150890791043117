import axios, {AxiosInstance, HeadersDefaults} from 'axios';
import { authEndpoints } from 'services/api';
import { ConnectError } from 'errors';
import { Tokens } from '@types';

type CustomHeaders = Record<string, string> & HeadersDefaults;

export const axiosInstance: AxiosInstance = axios.create({
	timeout: 20000,
	headers: {}
});

export async function refreshAccessToken(): Promise<Tokens> {
	const refreshToken = localStorage.getItem('refresh-token');
	if (!refreshToken) throw new ConnectError('AuthenticationError', 'Refresh token not found. Please login again', 'localStorage empty');

	return axiosInstance.post(authEndpoints.refresh, {
		refresh: refreshToken
	}).then(function (response) {
		setAuthorizationHeader(response.data.access);
		return response.data;
	}).catch(function (error) {
		throw error;
	});
}

export function setAuthorizationHeader(accessToken: string) {
	// Use type assertion to suppress TypeScript error
	(axiosInstance.defaults.headers as CustomHeaders)['Authorization'] = `Bearer ${accessToken}`;
}

export function removeAuthorizationHeader() {
	(axiosInstance.defaults.headers as CustomHeaders)['Authorization'];
}

export default axiosInstance;
