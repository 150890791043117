
import {Column} from '@tanstack/react-table';
import ReactSlider from 'react-slider';
import React, {useEffect, useState} from 'react';

interface RangeFiltersProps<T> {
  column: Column<T>;
}

export default function RangeFilters<T>({column} : RangeFiltersProps<T>) {

	const [minMax, setMinMax] = useState(column.getFacetedMinMaxValues()?.flat() as [number, number]);
	const [value, setValue] = useState(column.getFilterValue() as [number, number] ?? [minMax[0], minMax[1]] as [number, number]);
	const [thumbValues , setThumbValues] = useState(column.getFilterValue() as [number, number] ?? [minMax[0], minMax[1]] as [number, number]);
	const [showToolTip, setShowToolTip] = useState(false);
	const minDistance = 1;

	useEffect(() => {
		setMinMax(column.getFacetedMinMaxValues()?.flat() as [number, number]);
		setValue(column.getFilterValue() as [number, number] ?? column.getFacetedMinMaxValues()?.flat() as [number, number]);
		setThumbValues(column.getFilterValue() as [number, number] ?? column.getFacetedMinMaxValues()?.flat() as [number, number]);
	}, [column]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const newValue = value.map((v, i) => {
			if (i === index && !Number.isNaN(Number(e.target.value))) {
				return Number(e.target.value);
			}
			else return v;
		});
		setThumbValues(newValue as [number, number]);
	};

	const handleEnterOnThumbs = (value: [number, number]) => {
		setValue(value);
		setThumbValues(value);
		column.setFilterValue(value);
	};

	return (
		<>
			<div className={'w-full px-5 mt-7'}>
				<ReactSlider
					className="align-middle w-full items-center content-center justify-center h-2 flex pb-8 mt-2 "
					min={minMax[0]}
					value={value}
					max={minMax[1]}
					ariaValuetext={state => `Thumb value ${state.valueNow}`}
					renderThumb={(props, state) =>
						<input
							{...props}
							className={`w-7 h-7 text-xs text-center rounded-full border-2 dark:border-darkMode-b dark:bg-darkMode-tertiary content-center justify-center items-center align-middle ${state.index === 0 ? '-ml-3' : 'ml-3'}`}
							value={thumbValues[state.index]}
							onChange={(e) => handleInputChange(e, state.index)}
							onKeyUp={(e) => {
								const temp = thumbValues;
								if (e.key === 'Enter') {
									if (thumbValues[1] < thumbValues[0] + minDistance) {
										console.log('in here');
										temp[1] = thumbValues[0] + minDistance;
									}
									else if (thumbValues[0] > thumbValues[1] - minDistance) {
										console.log('no here');
										temp[0] = thumbValues[1] - minDistance;
									}
									else if (thumbValues[0] < minMax[0]) temp[0] = minMax[0];
									else if (thumbValues[1] > minMax[1]) temp[1] = minMax[1];
									console.log('temp', temp);
									handleEnterOnThumbs(temp as [number, number]);
								}
							}}
							onFocusCapture={(e) => { setShowToolTip(true);}}
							onBlur={(e) => { setShowToolTip(false);}}
						/>
					}
					pearling
					disabled

				/>
				<ReactSlider
					className="align-middle w-full items-center content-center justify-center h-2 flex pb-8 mt-1 "
					min={minMax[0]}
					value={value}
					max={minMax[1]}
					minDistance={minDistance}
					onChange={(value) => {
						setValue(value);
						setThumbValues(value);
					}}
					onAfterChange={(value) => column.setFilterValue(value)}
					ariaValuetext={state => `Thumb value ${state.valueNow}`}
					renderThumb={(props, state) => <span
						{...props}
						className={`bg-neutral-100 dark:bg-darkMode-primary dark:border-d-primary-40 w-7 h-7 border-2 border-primary-40 rounded-full content-center justify-center items-center align-middle 
						${state.index === 0 ? '-ml-3' : 'ml-3'} `}>
					</span>}
					renderTrack={(props) => <span
						{...props}
						className={
							`${props!.key === 'track-1' 
								? 'bg-primary-40 dark:bg-d-primary-40' 
								: 'bg-neutral-90 dark:bg-darkMode-primary'
							} 
						${props!.key === 'track-2' 
								? 'ml-3' 
								: ''} h-2 rounded-full`}>
					</span>}

				/>
			</div>
			{
				showToolTip
					? <p className={' text-center opacity-60 w-full'}>Press Enter to Apply Values</p>
					: null
			}
		</>
	);
}
