/**
 * Endpoints used to make API calls
 *
 * There are currently have two BASE_URLs:
 * Production API and Developer API, imported from environment variables.
 *
 */

import { updatePickupZone } from "@modules/ride/api";
import { group, profile } from "console";

const { REACT_APP_PROD_BASE_URL: PROD_BASE_URL, REACT_APP_DEV_BASE_URL: DEV_BASE_URL } = process.env;

// Endpoints used for authentication
// export const authEndpoints = {
// 	refresh: `${DEV_BASE_URL}api/auth/refresh/`,
// 	authenticate: `${DEV_BASE_URL}api/v2/users/me/`,
// 	login: `${DEV_BASE_URL}api/auth/login/`,
// 	updatePassword: `${DEV_BASE_URL}api/auth/update_password/`,
// };


export const authEndpoints = {
	refresh: `${DEV_BASE_URL}api/v2/auth/token/refresh/`,
	login: `${DEV_BASE_URL}api/v2/auth/login/`,
	updatePassword: `${PROD_BASE_URL}api/v2/auth/update_password/`,
};


export const userEndpoints = {
	user: `${DEV_BASE_URL}api/v2/users/my-account`,
	requestPasswordReset: `${DEV_BASE_URL}api/v2/users/password/request-reset`,
	passwordResetToken: `${DEV_BASE_URL}api/v2/users/password/verify-reset`,
	resetPassword: `${DEV_BASE_URL}api/v2/users/password/reset`,
	register: (email: string, theme = 'light') => `${DEV_BASE_URL}api/v2/users/new?email=${email}`,
	confirmRegistration: (code: string) => `${DEV_BASE_URL}api/v2/users/new?registration_code=${code}`,
};


// Endpoints used within the directory app
export const directoryEndpoints = {
	directory: () => `${DEV_BASE_URL}api/v2/directory/`,
	directoryGroups: () => `${directoryEndpoints.directory()}groups/`,
	directoryMembers: (groupId: number) => `${directoryEndpoints.directoryGroups()}${groupId}/`,

	fetchDirectoryGroup: (groupId: number) => `${PROD_BASE_URL}api/v2/directory/groups/${groupId}`,
	myContacts: (daysBack?: number) => `${PROD_BASE_URL}api/v2/user/my-contacts${daysBack ? `?recent=${daysBack}` : ''}`,
	myContact: (contactId: number) => `${PROD_BASE_URL}api/v2/user/my-contacts/${contactId}`,
	profileCard: (profileId: number) => `${DEV_BASE_URL}api/v2/organizations/profiles/${profileId}/card/`,
	profileCardSensitive: (profileId: number) => `${directoryEndpoints.profileCard(profileId)}sensitive/`,

	recentContacts: () => `${DEV_BASE_URL}api/v2/organizations/recent-contacts/`,

	viewableDirectories: () => `${DEV_BASE_URL}api/v2/organizations/viewable-directories/`,
	userDirectories: () => `${DEV_BASE_URL}api/v2/organizations/user-directories/`,
	orgDirectories: () => `${DEV_BASE_URL}api/v2/organizations/organization-directories/`,

	organizationDirectoryProfiles: (organizationId: number) => `${DEV_BASE_URL}api/v2/organizations/organization-directories/${organizationId}/`,

};


// Endpoints used within perform
export const performEndpoints = {
	observation: (id: number) => `${PROD_BASE_URL}api/perform/observation/${id}`,
	publishObservation: (id: number) => `${PROD_BASE_URL}api/perform/publishObservation/${id}`,
	unpublishObservation: (id: number) => `${PROD_BASE_URL}api/perform/unpublishObservation/${id}`,
	observationList: `${PROD_BASE_URL}api/perform/observationList/`,
	deleteObservation: (id: number) => `${PROD_BASE_URL}api/perform/deleteObservation/${id}`,
	observableProfiles: `${PROD_BASE_URL}api/perform/observableProfiles/`,
	availableTemplates: `${PROD_BASE_URL}api/perform/availableTemplates/`,
	newObservation: `${PROD_BASE_URL}api/perform/newObservation/`,
};


// Endpoints used within magnify
export const magnifyEndpoints = {
	// dashboardCard endpoint is not being used because the endpoint comes from the concatenation of the card slider property on the DashboardItem and the ID of the student
	dashboardList: `${PROD_BASE_URL}api/magnify/dashboards/`,
	dashboard: `${PROD_BASE_URL}api/magnify/dashboard/`,
	dashboardCard: `${PROD_BASE_URL}api/cards/studentProfile/`,
	recentDashboards: `${PROD_BASE_URL}api/magnify/recentDashboards/`,

	magnify: () => `${DEV_BASE_URL}api/v2/magnify/`,
	magnifyDataset: (dataset: 'students' | 'staff' | 'groups') => `${magnifyEndpoints.magnify()}${dataset}/`,
	magnifyCollection: (dataset: 'students' | 'staff' | 'groups', collection: 'orgs' | 'schools' | 'classrooms', collectionId: number) => `${magnifyEndpoints.magnifyDataset(dataset)}${collection}/${collectionId}/`,

	magnifyData: (dataset: 'students' | 'staff' | 'groups', collection: 'orgs' | 'schools' | 'classrooms', collectionId: number, config: string) => `${magnifyEndpoints.magnifyCollection(dataset, collection, collectionId)}${config}/data/`
};


// Endpoints used within announce
export const announceEndpoints = {
	me: (groupId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${groupId}/members/me/permissions`,
	announcements: `${DEV_BASE_URL}api/v2/announce/users/feed/`,
	savedAnnouncements: `${DEV_BASE_URL}api/v2/announce/users/saved/`,
	announceGroups: `${DEV_BASE_URL}api/v2/announce/groups/`,

	announceGroup: (announceGroupId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}`,

	announceGroupMembers: (announceGroupId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}/members/`,
	announceGroupEligibleMembers: (announceGroupId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}/members/eligible/`,
	addAnnounceGroupMember: (announceGroupId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}/members/new/`,
	announceGroupMember: (announceGroupId: number, announceMembershipId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}/members/${announceMembershipId}/`,

	announceGroupAnnouncements: (announceGroupId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements`,
	createAnnounceGroupAnnouncement: (announceGroupId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements/new/`,
	appendAnnouncementImage: (announceGroupId: number, announcementId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements/${announcementId}/update-img/`,
	announceGroupAnnouncement: (announceGroupId: number, announcementId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements/${announcementId}/`,

	removeAnnouncementImage: (announceGroupId: number, announcementId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements/${announcementId}/remove-img/`,

	approveAnnouncement: (announceGroupId: number, announcementId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements/${announcementId}/approve/`,
	rejectAnnouncement: (announceGroupId: number, announcementId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements/${announcementId}/reject/`,

	announceGroupAudience: (announceGroupId: number) => `${DEV_BASE_URL}api/v2/announce/groups/${announceGroupId}/audience`,

	announceGroupCategories: (organizationId: number) => `${DEV_BASE_URL}api/v2/announce/organizations/${organizationId}/categories`,
	announceGroupTags: (organizationId: number) => `${DEV_BASE_URL}api/v2/announce/organizations/${organizationId}/tags`,

	saveAnnouncement: (announcementId: number) => `${DEV_BASE_URL}api/v2/announce/users/save/${announcementId}/`,
	unsaveAnnouncement: (announcementId: number) => `${DEV_BASE_URL}api/v2/announce/users/unsave/${announcementId}/`,
};


// Endpoints used within pay
export const payEndpoints = {
	pay: `${DEV_BASE_URL}api/v2/pay/`,
	listings: `${DEV_BASE_URL}api/v2/pay/user/home/`,
	createListing: `${DEV_BASE_URL}api/v2/pay/listings/new/`,
	listing: (id: number) => `${DEV_BASE_URL}api/v2/pay/listings/${id}/edit/`,

	requestNewListing: `${DEV_BASE_URL}api/v2/pay/listings/requests/new/`,

	denyNewListingRequest: `${DEV_BASE_URL}api/v2/pay/listings/requests/deny/`,
	approveNewListingRequest: `${DEV_BASE_URL}api/v2/pay/listings/requests/approve/`,

	products: (adminGroupId: number) => `${DEV_BASE_URL}api/v2/pay/products/list/?admin_group_id=${adminGroupId}`,
	createProduct: `${DEV_BASE_URL}api/v2/pay/products/create`,
	updateProduct: `${DEV_BASE_URL}api/v2/pay/products/add-options-variations/`,

	productVariation: `${DEV_BASE_URL}api/v2/pay/products/variations/`,
	productOption: `${DEV_BASE_URL}api/v2/pay/products/options/`,
	productOptionChoice: (productOptionId: number) => `${DEV_BASE_URL}api/v2/pay/products/options/choices/${productOptionId}/choice-cud/`,

	cart: `${DEV_BASE_URL}api/v2/pay/cart/items/`,
	addToCart: `${DEV_BASE_URL}api/v2/pay/cart/items/add/`,
	removeFromCart: (cartItemId: number) => `${DEV_BASE_URL}api/v2/pay/cart/items/${cartItemId}/remove/`,
	updateCartItem: (cartItemId: number) => `${DEV_BASE_URL}api/v2/pay/cart/items/${cartItemId}/update/`,
};


// Endpoints used within ride
export const rideEndpoints = {
	base: () => `${DEV_BASE_URL}api/v2/ride/`,

	groups: () => `${rideEndpoints.base()}groups/`,
	group: (groupId: number) => `${rideEndpoints.groups()}${groupId}/`,
	membership: (groupId: number) => `${rideEndpoints.group(groupId)}members/me/permissions`,

	members: (groupId: number) => `${rideEndpoints.group(groupId)}members/`,
	addMember: (groupId: number) => `${rideEndpoints.members(groupId)}add/`,
	updateMember: (groupId: number, membershipId: number) => `${rideEndpoints.members(groupId)}${membershipId}/`,
	eligibleMembers: (groupId: number) => `${rideEndpoints.members(groupId)}eligible/`,

	pickupSessions: (groupId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/`,

	riders: (groupId: number) => `${rideEndpoints.group(groupId)}riders/`,
	rider: (groupId: number, riderId: number) => `${rideEndpoints.riders(groupId)}${riderId}/`,
	eligibleRiders: (groupId: number) => `${rideEndpoints.riders(groupId)}eligible/`,
	addRider: (groupId: number) => `${rideEndpoints.riders(groupId)}add/`,

	rideCode: (groupId: number, rideCodeId: number) => `${rideEndpoints.group(groupId)}ride-codes/${rideCodeId}/`,
	eligibleRideCodeRiders: (groupId: number, rideCodeId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/ride-codes/${rideCodeId}/eligible-riders/`,
	addRidersToRideCode: (groupId: number, rideCodeId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/riders/list-add/`,

	rideCodeRiders: (groupId: number, rideCodeId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/ride-codes/${rideCodeId}/riders/`,

	riderRideCodePDF: (groupId: number, riderId: number) => `${rideEndpoints.rider(groupId, riderId)}pdf/`,

	pickupZones: (groupId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-zones/`,

	rideCodes: (groupId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/ride-codes/`,
	newRideCode: (groupId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/ride-codes/new/`,
	rideCodePDFs: (groupId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/ride-codes/pdfs/`,
	scrambleRideCodes: (groupId: number, rideCodeId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/ride-codes/${rideCodeId}/scramble/`,

	actionCodes: (groupId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/action-codes/`,
	actionCodeDetail: (groupId: number, actionCodeId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/action-codes/${actionCodeId}/`,
	newActionCode: (groupId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/action-codes/new/`,

	newPickupZone: (groupId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-zones/new/`,
	updatePickupZone: (groupId: number, zoneId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-zones/${zoneId}/`,
	deletePickupZone: (groupId: number, zoneId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-zones/${zoneId}/`,

	session: (sessionId: number) => `${DEV_BASE_URL}api/v2/ride/pickup-sessions/${sessionId}`,
	activeSession: (groupId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/active/`,

	startSession: (groupId: number) => `${rideEndpoints.group(groupId)}pickup-sessions/start/`,
	endSession: (groupId: number) => `${rideEndpoints.group(groupId)}pickup-sessions/end/`,
	sessionDetail: (groupId: number, sessionId: number) => `${rideEndpoints.group(groupId)}pickup-sessions/${sessionId}`,

	sessionPickupNotices: (groupId: number, sessionId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/notices/`,

	stageRider: (groupId: number, sessionId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/stage/`,
	dispatchRider: (groupId: number, sessionId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/dispatch/`,
	sendRider: (groupId: number, sessionId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/send/`,
	receiveRider: (groupId: number, sessionId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/receive/`,
	releaseRider: (groupId: number, sessionId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/release/`,
	removeRider: (groupId: number, sessionId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/remove/`,
	undoRiderAction: (groupId: number, sessionId: number) => `${DEV_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/undo-last-action/`,
};

export const signEndpoints = {
	groups: `${DEV_BASE_URL}api/v2/sign/groups/`,
	group: (groupId: number) => `${DEV_BASE_URL}api/v2/sign/groups/${groupId}/`,

	document: (group_id: number, document_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/documents/${document_id}`,
	documents: (group_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/documents/`,
	newDocument: (group_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/documents/new/`,
	setActivityOfDocuments: (group_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/documents/set-is-active/`,
	deleteDocuments: (group_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/documents/delete/`,

	collection: (group_id: number, collection_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/document-collections/${collection_id}/`,
	collections: (group_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/document-collections`,
	newCollection: (group_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/document-collections/new/`,
	updateCollection: (group_id: number, collection_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/document-collections/${collection_id}/update/`,
	deleteCollection: (group_id: number, collection_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/document-collections/${collection_id}/delete/`,
	addDocumentsToCollection: (group_id: number, collection_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/document-collections/${collection_id}/add-documents/`,
	removeDocumentsFromCollection: (group_id: number, collection_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/document-collections/${collection_id}/remove-documents/`,

	members: (group_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/members/`,
	updateMember: (group_id: number, membership_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/members/${membership_id}/`,
	eligibleMembers: (group_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/members/eligible/`,
	addMember: (group_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/members/add/`,

	signatureRequests: (group_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/signature-requests/`,
	signatureRequest: (group_id: number, signature_request_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/signature-requests/${signature_request_id}/`,
	createSignatureRequest: (signGroupId: number) => `${DEV_BASE_URL}api/v2/sign/groups/${signGroupId}/signature-requests/new/`,

	availableDocuments: (signGroupId: number) => `${DEV_BASE_URL}api/v2/sign/groups/${signGroupId}/signature-requests/available-documents`,

	addRecipientToSignatureRequest: (group_id: number, signature_request_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/signature-requests/${signature_request_id}/add-recipients/`,

	packets: (group_id: number, signature_request_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/signature-requests/${signature_request_id}/packets/`,
	packet: (group_id: number, signature_request_id: number, packet_id: number) => `${DEV_BASE_URL}api/v2/sign/groups/${group_id}/signature-requests/${signature_request_id}/packets/${packet_id}/`,

	myPackets: `${DEV_BASE_URL}api/v2/sign/users/packets/`,
	signature: `${DEV_BASE_URL}api/v2/sign/users/signature/`,

	signField: (packet_id: number) => `${DEV_BASE_URL}api/v2/sign/users/packets/${packet_id}/sign/`,
	saveField: (packet_id: number) => `${DEV_BASE_URL}api/v2/sign/users/packets/${packet_id}/save/`,

	sendPacket: (signGroupId: number, signatureRequestId: number, packetId: number) => `${DEV_BASE_URL}api/v2/sign/groups/${signGroupId}/signature-requests/${signatureRequestId}/packets/${packetId}/send/`,
	sendPackets: (signGroupId: number, signatureRequestId: number) => `${DEV_BASE_URL}api/v2/sign/groups/${signGroupId}/signature-requests/${signatureRequestId}/send-packets/`,
};
