import React from 'react';

interface SignIconProps extends React.SVGProps<SVGSVGElement> {
  title?: string;
  titleId?: string;
}

function SignIcon({
	title,
	titleId,
	...props
}: SignIconProps, svgRef: React.LegacyRef<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200" fill="currentColor">
			<path className="st0" d="M415.6,972.8c-1.3-0.7-2.5-1.4-3.9-2c-7.1-3-15.1-2.1-22.9,1.7c-9.2,4.6-18.1,13.1-24.9,24
		c-2.6,4.1-5,8.6-7,13.4c-5.5,12.9-8.4,27.5-8.3,40c0.2,6.9,1.2,12.9,3.3,17.1c0.9,1.9,1.9,3.1,3.1,3.3c1.2,0.2,2.6,0.1,4.5-0.4l0,0
		c18.1-4.6,48-30.5,83-65.1C433.8,991,424.6,978.6,415.6,972.8z"/>
			<path className="st0" d="M814.1,157.8H941l-149.4-139v116.5C791.6,147.7,801.7,157.8,814.1,157.8z"/>
			<path className="st0" d="M890.9,972.7l-149.6,78.6c-8.2,4.3-18.3,1.2-22.7-7.1c-1.3-2.5-1.9-5.3-1.9-7.9l-6.7-168.1
		c-0.2-3.3,0.7-6.4,2.3-9.1l228.4-395.5V187.7H795.4c-9.3,0-41-2.6-36.9-36.9V18.7H92v1162.5h848.6V891.8l-43.1,74.4
		C895.9,969,893.6,971.2,890.9,972.7z M678.6,1053.3c-3.6,0-12.2-0.2-21-0.3c-30.4-0.6-62.9-1.3-87.9,9.7c-6,2.6-11.6,5.9-16.9,9.1
		c-22.7,13.5-41.9,24.9-66.4,1c-9-8.7-17.5-22.7-26.3-38.1c-36.7,35.4-69.1,62-92.3,67.9c-7.1,1.8-13.5,2-19.4,0.9
		c-12.4-2.4-21.1-10.2-26.6-21.3c-4.5-8.8-6.7-19.8-6.8-31.7c-0.3-17.1,3.6-36.7,10.9-53.7c2.6-6.3,5.8-12.5,9.6-18.3
		c10.2-16.1,23.6-28.8,38.3-36.1c16.3-8.1,34.1-9.8,51.1-2.5c2.8,1.2,5.7,2.8,8.7,4.7c12.5,8,23.3,21.2,33.2,36
		c17.3-17.9,35.5-37.2,53.7-56.5c0.4-0.4,0.7-0.8,1.2-1.2l0,0c6.6-6.6,17.3-6.6,23.9,0c6.6,6.6,6.6,17.3,0,23.9l-0.4,0.5
		c-20.4,21.6-40.7,43.1-60.4,63l1,1.8c9,15.6,17.3,30,24.2,36.7c6.1,5.9,15.1,0.6,25.8-5.7c6.4-3.8,13.2-7.9,20.5-11.1
		c31.7-13.9,68.1-13.1,102.2-12.5c5.2,0.1,10.6,0.2,20.2,0.2c9.3,0,16.9,7.6,16.9,16.9C695.5,1045.7,687.9,1053.3,678.6,1053.3z"/>
			<path className="st0" d="M1093,475.3c-6.5-8.1-14.7-15-23.9-20.3c-9.2-5.3-19.2-9-29.5-10.5c-24.5-3.8-50.4,4.6-67.2,31.4
		c-0.3,0.6-0.6,1.2-1,1.8c-0.2,0.3-0.3,0.6-0.6,0.9l-24.3,42.3l0,0l127.1,73.4l24.5-42.3C1114.6,523.1,1109,495.3,1093,475.3z"/>
			<path className="st0" d="M913.2,578.1L913.2,578.1l-28.4,49.5l-135,233.8l0.3,0.2l-5.4,9.3l64.4,37.1c-0.1-0.1-0.3-0.1-0.5-0.2
		c-0.4-0.2-0.7-0.4-1.1-0.7l-62.8-36.3l5.6,138.2l122.4-64.4l183.9-321.4l-127.1-73.4L913.2,578.1z"/>
		</svg>
	);
}

const ForwardRef = React.forwardRef(SignIcon);
export default ForwardRef;