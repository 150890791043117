import React from 'react';
import useDetectSticky from './hooks/useDetectSticky';

interface StickyHeaderProps {
  sticky?: boolean;
  includeSearch?: boolean;
  children: React.ReactNode;
}

export default function StickyHeader({ children,  ...props }: StickyHeaderProps) {
	const [isSticky, ref] = useDetectSticky();

	return (
		<div
			id={'sticky-header'}
			className={(isSticky ? ' isSticky ' : '') + ' sticky top-[-1px] bg-neutral-95 z-20 pt-6 pb-6 '}
			ref={ref} // Assert ref as RefObject<HTMLDivElement>
			{...props}
		>
			{children}
		</div>
	);
}
